/**
 * @file
 * Initialize and handle slick carousel
 */

// JavaScript should be made compatible with libraries other than jQuery by
// wrapping it with an "anonymous closure". See:
// - http://drupal.org/node/1446420
// - http://www.adequatelygood.com/2010/3/JavaScript-Module-Pattern-In-Depth
!function ($) {
  // Always use strict mode to enable better error handling in modern browsers.
  "use strict";

  $(document).ready(function() {

    // Helper function to add/remove prev/next slide classes
    // element - the next center slide
    // toggle - boolean to determine add/remove
    function adjustClasses(element, toggle) {
      var prevSlide = element.prev().attr('aria-describedby');
      $mainSlider.find('[aria-describedby="' + prevSlide + '"]').toggleClass('previous-slide', toggle);

      var nextSlide = element.next().attr('aria-describedby');
      $mainSlider.find('[aria-describedby="' + nextSlide + '"]').toggleClass('next-slide', toggle);
    }

    // Helper function to clear either of the state classes on a slide
    // @TODO: used for fine tuning the slide animation, leaving it for now, remove if not used
    // element - the next center slide
    function clearClasses(element) {
      var prevSlide = element.prev().attr('aria-describedby');
      $mainSlider.find('[aria-describedby="' + prevSlide + '"]').removeClass('previous-slide next-slide transition-slide');

      var nextSlide = element.next().attr('aria-describedby');
      $mainSlider.find('[aria-describedby="' + nextSlide + '"]').removeClass('previous-slide next-slide transition-slide');
    }

    // Helper function to clear either of the state classes on a slide
    // @TODO: used for fine tuning the slide animation, leaving it for now, remove if not used
    // element - the next center slide
    function mainSlideClasses(element) {
      var thisSlide = element.attr('aria-describedby');
      $mainSlider.find('[aria-describedby="' + thisSlide + '"]').removeClass('previous-slide next-slide transition-slide');
    }

    // Helper function to clear either of the state classes on a slide
    // @TODO: used for fine tuning the slide animation, leaving it for now, remove if not used
    // element - the next center slide
    // toggle - boolean to determine add/remove
    function transitionClass(element, toggle) {
      var thisSlide = element.attr('aria-describedby');
      $mainSlider.find('[aria-describedby="' + thisSlide + '"]').toggleClass('transition-slide', toggle);
    }


    /**
     * Homepage slider
     */
    var $mainSlider = $('.slick-slider-main');

    // Add events to handling left/right keying through dots
    $mainSlider.on('keyup', '.slick-dots button', function(e) {
      if (e.which === 37) {
        // left arrow
        $(this).parent().prev().children('button').focus();
      } else if (e.which === 39) {
        // right arrow
        $(this).parent().next().children('button').focus();
      }
    });

    // Delegate click event to the entire '.next-slide' element for transition
    $mainSlider.on('click', '.next-slide', function() {
      $mainSlider.slick('slickNext');
    });

    // Delegate click event to the entire '.prev-slide' element for transition
    $mainSlider.on('click', '.previous-slide', function() {
      $mainSlider.slick('slickPrev');
    });

    // Add previous and next classes for slides on init
    $mainSlider.on('init', function(event, slick) {
      // place this in a setTimeout to adjust for an issue with slick init, cloned slides don't keep the class
      setTimeout(function() {
        var $initialSlide = $(slick.$slides.get(slick.currentSlide));
        adjustClasses($initialSlide, true);
      }, 10);
    });
    
    // Manage classes for previous and next slides on transitions
    $mainSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
      var $currentSlide = $(slick.$slides.get(currentSlide));
      adjustClasses($currentSlide, false);
      var $nextSlide = $(slick.$slides.get(nextSlide));
      adjustClasses($nextSlide, true);
    });
    

    $mainSlider.slick({
      mobileFirst: true,
      infinite: true,
      speed: 300,
      autoplay: true,
      autoplaySpeed: 7000,
      arrows: true,
      useTransform: true,
      //cssEase: 'cubic-bezier(0.000, 0.630, 1.000, 0.255)',
      centerMode: true,
      dots: false,
      slidesToShow: 1,
      responsive: [{
        breakpoint: 640,
        settings: {
          dots: true
        }
      }]
    });


    /**
     * Promoted Item Slider
     */
    $('.slick-slider-promoted').slick({
      useTransform: true,
      mobileFirst: true,
      dots: true
    });


    /**
     * Gallery slider
     */
    $('.slick-slider-gallery').slick({
      useTransform: true,
      mobileFirst: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      centerMode: true,
      infinite: true,
      responsive: [
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4
          }
        }
      ]
    });

  });


}(jQuery);

/**
 * @file
 * Manage simple dropdowns
 */

!function ($) {
  // Always use strict mode to enable better error handling in modern browsers.
  "use strict";

  // Init masonry with options
  $(document).ready(function() {

    var $dropdownContainers = $('.simple-dropdown-container'),
        $body = $('body');

    $dropdownContainers.each(function() {
      var _this = $(this),
          $toggle = _this.find('.dropdown-toggle'),
          $dropdown = _this.find('.dropdown'),
          $links = _this.find('a');

      $toggle.on('click', function(e) {
        e.preventDefault();
        _this.toggleClass('dropdown-active');
      });

      $body.on('click', function(e) {
        if (!$(e.target).closest('.simple-dropdown-container').length) {
          $dropdownContainers.removeClass('dropdown-active');
        }
      });

    });



  });

}(jQuery);

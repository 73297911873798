!function($){
	$(document).ready(function() {
			var billSummaryWrapper = $('.field-name-field-bill-summary');
			var headerHeight = $('.node-bill header').height();
			var asideHeight = $('.aside').height();
			var initialHeight = asideHeight - headerHeight;
			if(billSummaryWrapper.height() > initialHeight + 100){
			billSummaryWrapper.css({
					"max-height": initialHeight
					});
			$('.read-more').css({
					"visibility": "visible"
					})
			$('.read-more .button').click(function(){
					var $el, $ps, $up, totalHeight;
					totalHeight = 100;

					$el = $(this);
					$p  = $el.parent();
					$up = $p.parent();
					$ps = $up.find(".field-item");
					if($el.text().toLowerCase().indexOf('less') >= 0){
					$('html, body').animate({
						scrollTop: $('html, body').offset().top + initialHeight + $('.header-wrapper').height() + headerHeight
						}, 1000);
					$up
					.animate({
						"height": initialHeight
						}, 2000)
					$p
					.css({
						"background-image": "linear-gradient(to bottom,transparent,white)",
						});
				$el.text("Read More");
				return false;
				}
				// measure how tall inside should be by adding together heights of all inside paragraphs (except read-more paragraph)
				$ps.each(function() {
						totalHeight += $(this).outerHeight();
						});
				$up
				.css({
						// Set height to prevent instant jumpdown when max height is removed
						"height": $up.height(),
						"max-height": 9999,
						})
				.animate({
						"height": totalHeight
						}, 1000);
				$p
				.css({
						"background-image": "none",
						});

				//						$p.fadeOut();
				$el.text("Read Less");

				// prevent jump-down
				return false;
			});
		}
	});

}(jQuery);

/**
 * @file
 * Skip link for accessibility
 */

!function ($) {
  // Always use strict mode to enable better error handling in modern browsers.
  "use strict";

  $(document).ready(function() {

    var $confirmLinks = $('.contact-website');
    $confirmLinks.on("click", function(e) {
      var answer = confirm("You are now leaving the site of the Colorado General Assembly!");
      if (!answer) {
        e.preventDefault();
      }
    });

  });

}(jQuery);
/**
 * @file
 * A JavaScript file for the session schedule behaviors.
 */

// JavaScript should be made compatible with libraries other than jQuery by
// wrapping it with an "anonymous closure". See:
// - http://drupal.org/node/1446420
// - http://www.adequatelygood.com/2010/3/JavaScript-Module-Pattern-In-Depth
!function ($) {
  // Always use strict mode to enable better error handling in modern browsers.
  "use strict";

  function startsWith(needle, haystack, offset) {
    var offset = offset || 0;
    return haystack.substring(offset, needle.length) === needle;
  }

  function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
      var pair = vars[i].split("=");
      if(pair[0] == variable){return pair[1];}
    }
    return(false);
  }

  // Helper function to return true if value is found in array
  function isInArray(value, array) {
    return array.indexOf(value) > -1;
  }

  // Helper function will iterate through needleArray and return true if EVERY item is also found in haystackArray
  function arrayInArray(needleArray, haystackArray) {
    for (var i = 0; i < needleArray.length; i++) {
      if (haystackArray.indexOf(needleArray[i]) < 0) {
        //console.log(haystackArray.indexOf(needleArray[i]));
        return false;
      }
    }
    return true;
  }


  // Initialize list.js for session schedule in a Drupal behavior as tribute to El Drupalo.
  Drupal.behaviors.listJs = {
    attach: function (context) {

      var $sessionScheduleBlock = $('#block-cga-sessions-session-schedule'),
        $sessionSchedule = $sessionScheduleBlock.find('.session-schedule'),
        $sessionScheduleContent = $sessionSchedule.find('.session-schedule-content'),
        $filterView = $sessionSchedule.find('#session-schedule-view'),
        $filterType = $sessionSchedule.find('#session-schedule-type');

      // Handle the view change
      $filterView.on('change', function() {
        $sessionSchedule.removeClass(function(index, className) {
          var classes = className.split(' ');
          var classesToRemove = classes.filter(function(value){
            return startsWith('view-', value);
          });
          classesToRemove = classesToRemove.join(' ');
          return classesToRemove;
        });
        $sessionSchedule.addClass($(this).val());
      });

      // List.js for sorting all of the results
      var options = {
        valueNames: [
          { data: ['type'] }
        ]
      };

      // Initialize our array of defined lists
      var allLists = [];

      // Get all lists
      var $lists = $('.schedule-item-list');

      // Set no results copy
      var noResults = '<p class="no-filter-results">There are no activity results from the applied filters.</p>';

      // Initialize each list and push to our array
      $lists.each(function(index, value) {
        var listId = $(this).attr('id');
        var list = new List(listId, options)
        list['parentContainer'] = $(list.listContainer);
        list['isEmpty'] = false;
        allLists.push(list);
      });

      // Iterate filters over all lists on change
      $filterType.on('change', function() {
        var type = $(this).val().split(' ');
        for (var i = 0; i < allLists.length; i++) {
          allLists[i].filter(function(item) {
            var itemTypes = item.values().type.split(' ');

            if (isInArray('all', type)) {
              return true;
            }
            return arrayInArray(type, itemTypes);
          });
          if (allLists[i]['visibleItems'].length === 0) {
            if (!allLists[i]['isEmpty']) {
              allLists[i].parentContainer.after(noResults);
              allLists[i]['isEmpty'] = true;
            }
          } else if (allLists[i]['visibleItems'].length > 0) {
            allLists[i].parentContainer.siblings('.no-filter-results').remove();
            allLists[i]['isEmpty'] = false;
          }
        }
      });

      // This will only trigger on page load
      if (!$(context).closest('html').length > 0) {
        var type = getQueryVariable('type');
        if (type) {
          type = type.split('+').join(' ');
          // Check to make sure an option with that value exists before trying to set it
          if ($filterType.find('option[value="' + type + '"]').length > 0) {
            $filterType.val(type);
            $filterType.change();
          }
        }
      }
    }
  };

}(jQuery);

/**
 * @file
 * Custom multiselects with Select2
 */

!function ($) {
  // Always use strict mode to enable better error handling in modern browsers.
  "use strict";

  // Exclude these multiselects from the default select2 initialization, they have special handling
  var selectListsToExclude = [
    '#legislator-search',
    '#edit-field-subjects', // handled in search-widgets.js
    '#edit-field-department-topic-target-id'
  ];
  selectListsToExclude = selectListsToExclude.join(', ');

  Drupal.behaviors.multiSelects = {
    attach: function (context) {

      /**
       * Select2
       */
      $("select[multiple]").not(selectListsToExclude).select2();
    }
  };

  Drupal.behaviors.legislatorSearch = {
    attach: function (context) {
      /**
       * Select2
       */
      var $leg_select = $("#legislator-search").select2({
        width: "100%"
      });
      $leg_select.on('select2:select', function (e) {
        var path = $(this).val();
        $leg_select.select2('val', '');
        var base = Drupal.settings.basePath;
        window.location.href = base + path;
      });
      $leg_select.on('select2:open', function (e) {
        $('.select2-dropdown').hide();
        $(".select2-search__field").keyup(function() {
          $('.select2-dropdown').show();
        });
      });
    }
  };

  Drupal.behaviors.budgetSelect = {
    attach: function (context) {
      var $budgetDocumentsView = $('.view-id-budget_documents');

      $budgetDocumentsView.once('budget-select', function() {
        var $taxonomyWidget = $('.views-widget-filter-field_department_topic_target_id'),
            $taxonomyMultiSelect = $taxonomyWidget.find('select');
        // Create new select jQuery object, add options from original
        var $selectControl = $('<select>').attr('data-target', $taxonomyMultiSelect.attr('id')).append($taxonomyMultiSelect.html());
        // Make our new single select control the multi select
        $selectControl.on('change', function() {
          $taxonomyMultiSelect.val($(this).val());
        });
        // Add single select to DOM
        $taxonomyWidget.append($selectControl);
      });
    }
  };


}(jQuery);
/**
 * @file
 * Custom radio button and checkboxes with iCheck
 */

!function ($) {
  // Always use strict mode to enable better error handling in modern browsers.
  "use strict";

  Drupal.behaviors.radiosChecks = {
    attach: function (context) {

      /**
       * iCheck
       */
      $('input', context).not( "input.noicheck" ).icheck({
        //handle: 'checkbox',
        handle: '',
        checkboxClass: 'icheckbox-default',
        radioClass: 'iradio-default',
        focusClass: 'focus',
        mirror: true,
        autoAjax: false
      });

    }
  }

}(jQuery);

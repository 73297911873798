/**
 * @file
 * A JavaScript file for the theme.
 */

// JavaScript should be made compatible with libraries other than jQuery by
// wrapping it with an "anonymous closure". See:
// - http://drupal.org/node/1446420
// - http://www.adequatelygood.com/2010/3/JavaScript-Module-Pattern-In-Depth
!function ($) {
  // Always use strict mode to enable better error handling in modern browsers.
  "use strict";

  $(document).ready(function() {

    /*
     * Photoswipe
     * This inits a photoswipe gallery with some basic settings and allows for many galleries on a page
     */

    // Init some variables
    var $pswpImages,
      pswpArray = [];

    // Function to run when a photoswipe gallery is opened
    var openPhotoSwipe = function(openIndex, $activeGallery) {
      var pswpElement = document.querySelectorAll('.pswp')[0],
        gallery,
        options,
        pswpArray;

      // define options
      options = {
        history: false,
        index: openIndex,
        showAnimationDuration: 500,
        hideAnimationDuration: 500,
        // if thumbnails and full size image have different aspect ratios, set to true
        showHideOpacity:true,
        getThumbBoundsFn: function(openIndex) {
          // find thumbnail element
          //var thumbnail = document.querySelectorAll('.simple-image-gallery-item img')[openIndex];
          var thumbnail = $('.photoswipe-image-gallery-item img', $activeGallery)[openIndex];
          // get window scroll Y
          var pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
          // optionally get horizontal scroll
          // get position of element relative to viewport
          var rect = thumbnail.getBoundingClientRect();
          // w = width
          return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
        }

      };

      // Build gallery array based on the items in the gallery opened
      $pswpImages = $activeGallery.find('.photoswipe-image-gallery-item a');
      pswpArray = [];
      // build items array to pass to PhotoSwipe constructor
      $pswpImages.each(function(i) {
        var currentObject = {
          src: $(this).attr('href'),
          w: $(this).attr('data-ps-width'),
          h: $(this).attr('data-ps-height'),
          msrc: $(this).attr('href'),
          title: $(this).attr('data-ps-caption')
        };
        pswpArray.push(currentObject);
      });

      // Contstruct new gallery and init
      gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, pswpArray, options);
      gallery.init();

      // Get flexslider object for current active gallery
      var $activeSlider = $activeGallery.closest('.slick-slider-gallery');
      gallery.listen('afterChange', function() {
        // Based on photoswipe item transitioned to, get the slide group to adjust flexslider to
        $activeSlider.slick('slickGoTo', gallery.getCurrentIndex(), true);
      });
    };

    // open PhotoSwipe gallery on click, passing index of clicked item and the current gallery
    $('.photoswipe-image-gallery-item a').click(function(e) {
      e.preventDefault();
      // Get active gallery
      var $activeGallery = $(this).closest('.photoswipe-gallery');
      // get index of clicked item within only the set of other gallery items
      var currentIndex = $activeGallery.find('.photoswipe-image-gallery-item').index($(this).closest('.photoswipe-image-gallery-item'));
      openPhotoSwipe(currentIndex, $activeGallery);
    });


  });


}(jQuery);

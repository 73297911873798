/**
 * @file
 * A JavaScript file for the session schedule behaviors.
 */

// JavaScript should be made compatible with libraries other than jQuery by
// wrapping it with an "anonymous closure". See:
// - http://drupal.org/node/1446420
// - http://www.adequatelygood.com/2010/3/JavaScript-Module-Pattern-In-Depth
!function ($) {
  // Always use strict mode to enable better error handling in modern browsers.
  "use strict";

  $(document).ready(function() {

    // Automatically submits form when sort dropdown is changed. Add form id here to enable on the form.
    var $exposedWidgets = $('#views-exposed-form-bill-search-full, #views-exposed-form-publication-search-full, #views-exposed-form-audit-search-audit-full');

    $exposedWidgets.each(function() {
      var $submitButtonWrapper = $(this).find('.views-submit-button'),
          $submitButton = $(this).find('.form-submit'),
          $sortDropdown = $(this).find('.form-item-sort-bef-combine select'),
          $this = $(this);

      var $subjectSelect = $this.find('#edit-field-subjects').select2({
        placeholder: "- All -"
      });

      // Add handler to submit form on sort change
      $sortDropdown.on('change', function() {
        $submitButton.trigger('click');
      });

      // Add reset button
      $submitButtonWrapper.append('<a href="#" class="clear-fields">Clear filters</a>');
      var $clearFields = $submitButtonWrapper.find('.clear-fields');

      // Set select fields to first option, clear text fields, and reset multiselect
      $clearFields.on("click", function(e) {
        e.preventDefault();
        $this.find('select').not('[multiple]').each(function() {
          $(this).val($(this).find('option:first').val());
        });
        $subjectSelect.val(null).trigger('change');
        $this.find('input[type="text"]').each(function() {
          $(this).val('');
        });
      });
    });


  });

}(jQuery);

/**
 * @file
 * Logic to close notification bar.
 */

!function ($) {
  // Always use strict mode to enable better error handling in modern browsers.
  "use strict";

  $(document).ready(function() {
    $("#notification-bar-messages .content-right a").click(function() {
      $("#notification-bar-messages").slideUp("slow", function() { $(this).remove(); });
    });
    
  });

}(jQuery);

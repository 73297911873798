/**
 * @file
 * Skip link for accessibility
 */

!function ($) {
  // Always use strict mode to enable better error handling in modern browsers.
  "use strict";

  // Add selectors specific to the tables you want to target in the array below.
  var targets = [
    '#legislators-overview-table',
    '.view-display-id-block_1 table',
    '#block-views-budget-documents-block table',
    '.view-id-laws_overview table'
  ];
  targets = targets.join(', ');

  Drupal.behaviors.responsiveTables = {
    attach: function (context) {

      /**
       * Add responsive tables class and attributes where tables AREN'T templated and do want responsive behavior.
       * Leaving this to targeted tables because we might not want all tables to use this behavior, ie - scraped tables
       * should not have this applied, since they aren't tabular data it will likely make them not make sense
       *
       * Note: NOT using context as jQuery selector context `$('.selector', context)` so we can add block classes or ids in the above array and they
       * will work as expected. Using Drupal's once() method to ensure content elsewhere on the page won't get processed
       * multiple times.
       */
      var $tables = $(targets);
      // Iterate through all tables
        $tables.each(function() {
          // Do once (for behaviors)
          $(this).once('responsive-tables', function() {
            $(this).responsiveTables();
          });
        });
    }
  }

}(jQuery);
/**
 * @file
 * A JavaScript file for the theme.
 */

// JavaScript should be made compatible with libraries other than jQuery by
// wrapping it with an "anonymous closure". See:
// - http://drupal.org/node/1446420
// - http://www.adequatelygood.com/2010/3/JavaScript-Module-Pattern-In-Depth
!function ($) {
  // Always use strict mode to enable better error handling in modern browsers.
  "use strict";


  // Init foundation with options
  $(document).ready(function() {

    var $window = $(window),
        position = $window.scrollTop(),
        $scrollMenuWrapper = $('.scroll-menu-wrapper'),
        $mobileMainMenuBar = $('.mobile-main-menu-bar'),
        $mobileAgencyMenuBar = $('.mobile-agency-menu-bar'),
        $body = $('body'),
        scrollMenuThreshold = 300,
        hitThreshold = false,
        scrollDirectionDown;


    if ($body.hasClass('node-type-agency') || $body.hasClass('node-type-agency-detail')) {
      scrollMenuThreshold = 700;
    }

    function scrollingDown() {
      $scrollMenuWrapper.removeClass('show-main-menu');
      $mobileMainMenuBar.removeClass('show-main-menu');
      $mobileAgencyMenuBar.removeClass('show-main-menu');
    }
    function scrollingUp() {
      $scrollMenuWrapper.addClass('show-main-menu');
      $mobileMainMenuBar.addClass('show-main-menu');
      $mobileAgencyMenuBar.addClass('show-main-menu');
    }

    function pastThreshold() {
      $scrollMenuWrapper.addClass('active-menus');
      $mobileMainMenuBar.addClass('active-menus');
      $mobileAgencyMenuBar.addClass('active-menus');
    }
    function beforeThreshold() {
      $scrollMenuWrapper.removeClass('active-menus');
      $mobileMainMenuBar.removeClass('active-menus');
      $mobileAgencyMenuBar.removeClass('active-menus');
    }

    $window.on("scroll", function() {
      var scroll = $window.scrollTop();
      if (scroll == position) {
        // if equal, simply return
        return false;
      } else if (scroll > position) {
        // scrolling down
        if (scrollDirectionDown !== true) {
          scrollingDown();
          scrollDirectionDown = true;
        }
      } else {
        // scrolling up
        if (scrollDirectionDown !== false) {
          scrollingUp();
          scrollDirectionDown = false;
        }
      }
      position = scroll;

      if (scroll > scrollMenuThreshold) {
        if (hitThreshold === false) {
          pastThreshold();
          hitThreshold = true;
        }
      } else {
        if (hitThreshold === true) {
          beforeThreshold();
          hitThreshold = false;
        }
      }
    });

    $('.scroll-to-top').click(function(){
      $('html, body').animate({scrollTop : 0}, 800);
      return false;
    });

  });

}(jQuery);

/**
 * @file
 * A JavaScript file for the theme.
 */

// JavaScript should be made compatible with libraries other than jQuery by
// wrapping it with an "anonymous closure". See:
// - http://drupal.org/node/1446420
// - http://www.adequatelygood.com/2010/3/JavaScript-Module-Pattern-In-Depth
!function ($) {
  // Always use strict mode to enable better error handling in modern browsers.
  "use strict";


  // Init foundation with options
  $(document).ready(function() {

    $(document).foundation();

    // Listen for a menu event from ANYWHERE and catch it when it bubbles up to the $(document)
    $(document).on('menus.closing.all menus.closing.agency menus.closing.main', function() {
      //console.log('menus are closing! hurry up!');
    });
    $(document).on('menus.opening.all menus.opening.agency menus.opening.main', function() {
      //console.log('get ready, the menus are opening!');
    });

    // Example of listening for a menu event triggered FROM a specific element, just like .on('click', '.target', someFunction())
    $(document).on('menus.closing.all menus.closing.agency menus.closing.main', '.inline-mobile-agency-menu-toggle', function() {
      //console.log('Why hello, you used ME to adjust the menu: ' + $(this).html());
    });

    //Foundation.Accordion.defaults.allowAllClosed = true;
    //Foundation.Accordion.defaults['multiExpand'] = true;

    /**
     * Function to handle menu toggles and send custom menu events
     * target - the menu name, ['main', 'agency', 'all']
     * toggleAction - possible options ['open', 'close']
     * $clickedObject - pass the jQuery object that was clicked if you want the event to be triggered from that
     */
    function toggleMenus(target, toggleAction, $clickedObject) {
      var toggle,
          validTargets = ['main', 'agency', 'all'],
          eventName;
      if (toggleAction === 'open') {
        toggle = true;
      } else if (toggleAction === 'close') {
        toggle = false;
      } else if (toggleAction !== undefined) {
        //console.log('Invalid toggleAction parameter \'' + toggleAction + '\' passed to toggleMenus function.');
        return false;
      }

      if (validTargets.indexOf(target) < 0) {
        //console.log('Invalid target parameter \'' + target + '\' passed to toggleMenus function.');
        return false;
      }

      // Handle the actual menu toggle
      if (target === 'all') {
        $body.toggleClass('main-mobile-menu-open agency-mobile-menu-open', toggle);
        $('.mobile-menu-wrapper').toggleClass('show-mobile-menu', toggle);
      } else {
        $body.toggleClass(target + '-mobile-menu-open', toggle);
        $('.mobile-menu-wrapper-' + target).toggleClass('show-mobile-menu', toggle);
      }

      // Broadcast the proper event, allow the listener to determine all v main v agency
      // if _this (clicked item) is passed, trigger from there, else trigger on $(document)
      eventName = (toggle) ? 'menus.opening.' + target : 'menus.closing.' + target;
      if ($clickedObject instanceof jQuery) {
        $clickedObject.trigger(eventName);
      } else {
        $(document).trigger(eventName);
      }
    }


    // Create a drilldown and dropdown menu for each menu item
    var $mainMenu = $('.menu-block-1'),
        $utilityMenu = $('.menu-block-2'),
        $body = $('body'),
        $searchBlock,
        $mobileHeaderMenu,
        $mobileUtilityMenu,
        $scrollHeaderMenu,
        $mobileAgencyMenu,
        $scrollAgencyMenu;

    // Clone the main menu
    $mobileHeaderMenu = $mainMenu.clone();
    // Add an extra li for the `more` item
    $mobileHeaderMenu.append('<li class="menu__item is-leaf has-dropdown more-dropdown"></li>');
    // Clone the utility nav
    $mobileUtilityMenu = $utilityMenu.clone();
    // Remove what would be any third level dropdowns
    $mobileUtilityMenu.find('.dropdown').remove();
    $mobileUtilityMenu.find('.menu__item').removeClass('has-dropdown');
    // Add the utility nav to the `more` dropdown
    $mobileHeaderMenu.find('.more-dropdown').append('<a href="#" class="more-dropdown-link menu__link">More</a>').append($mobileUtilityMenu.addClass('dropdown'));
    // Clone the mobile nav for the fullscreen scrolling nav
    $scrollHeaderMenu = $mobileHeaderMenu.clone();
    // Add an extra li for the `search` item, and clone the search block to this item
    $mobileHeaderMenu.prepend('<li class="menu__item is-leaf menu-search"></li>');
    $searchBlock = $('.header-search').clone();
    $mobileHeaderMenu.find('.menu-search').append($searchBlock);
    // Add home link to scroll menu
    $scrollHeaderMenu.prepend('<li class="menu__item home-menu-item"><a href="/" class="home-menu-link">Home</a></li>');
    // Initialize dropdown menu on scrolling nav
    var dropdownScrollHeaderMenu = new Foundation.DropdownMenu($scrollHeaderMenu.addClass('dropdown menu'));
    $mobileHeaderMenu.addClass('vertical');


    //$('.page-wrapper').prepend('<div class="scroll-menu-wrapper"><div class="scroll-menus"></div></div><div class="mobile-menu-wrapper"><div class="mobile-menus"></div></div>');
    //var $mobileMenus = $('.mobile-menus');
    var $mobileMenusMainMenu = $('.mobile-menu-main');
    var $mobileMenusAgencyMenu = $('.mobile-menu-agency');
    var $scrollMenus = $('.scroll-menus');

    // Add scrolling menu to DOM
    $scrollMenus.append('<nav class="main-scroll-menu"></nav>').find('.main-scroll-menu').append($scrollHeaderMenu);

    // Process mobile menu then add to DOM
    $mobileHeaderMenu.find('li.has-dropdown').each(function() {
      var $menuItem = $(this);
      $menuItem.children('.menu__link').after('<a href="#" class="mobile-menu-toggle" aria-label="Submenu Toggle">Submenu Toggle</a>');
      $menuItem.children('.mobile-menu-toggle').on('click', function(e) {
        e.preventDefault();
        $(this).siblings('.dropdown').slideToggle(300);
        $(this).toggleClass('toggle-open');
      });
    });
    $mobileMenusMainMenu.append('<nav class="main-mobile-menu"></nav>').find('.main-mobile-menu').append($mobileHeaderMenu);

    var dropdownMainMenu = new Foundation.DropdownMenu($mainMenu.addClass('dropdown menu'));
    var dropdownUtilityMenu = new Foundation.DropdownMenu($utilityMenu.addClass('dropdown menu'));


    // Agency Menus
    var $agencyMenu = $('.region-content-top .menu-block-wrapper');
    if ($agencyMenu.length > 0) {
      // Add mobile bar that will replace inline agency menu
      var $agencyMenuWrapper = $('.region-content-top .block-menu-block');
      var agencyTitle = $('.agency-page-header-content .agency-name').text();
      var agencyHomeLink =$('#homeLink').attr('href');
      $agencyMenuWrapper.append('<div class="inline-agency-menu-bar"><a href="#" class="inline-mobile-agency-menu-toggle" data-toggle-target="agency"><span>' + agencyTitle + ' Menu</span></a></div>');
      // Add agency title to the mobile menu bar
      $('.mobile-agency-menu-bar .agency-title').text(agencyTitle);

      $mobileAgencyMenu = $agencyMenu.clone();
      $mobileAgencyMenu.addClass('vertical');
      // @TODO: Try to find a way to refactor this?
      // Process the mobile menu to have dropdown toggles
      $mobileAgencyMenu.find('li.has-dropdown').each(function() {
        var $menuItem = $(this);
        $menuItem.children('.menu__link').after('<a href="#" class="mobile-menu-toggle">Toggle Submenu</a>');
        $menuItem.children('.mobile-menu-toggle').on('click', function(e) {
          e.preventDefault();
          $(this).siblings('.dropdown').slideToggle(300);
          $(this).toggleClass('toggle-open');
        });
      });
      $mobileMenusAgencyMenu.append('<nav class="agency-mobile-menu"></nav>').find('.agency-mobile-menu').append($mobileAgencyMenu);
      $scrollAgencyMenu = $agencyMenu.clone();
      $scrollAgencyMenu.prepend('<li class="menu__item no-link"><a href="'+agencyHomeLink+'"><span class="agency-title">' + agencyTitle + '</span></a></li>');
      var dropdownScrollAgencyMenu = new Foundation.DropdownMenu($scrollAgencyMenu.addClass('dropdown menu'));
      var dropdownAgencyMenu = new Foundation.DropdownMenu($agencyMenu.addClass('dropdown menu'));
      $scrollMenus.append('<nav class="agency-scroll-menu"></nav>').find('.agency-scroll-menu').append($scrollAgencyMenu);
    } else {
      // If no agency menu, remove the items from the DOM
      $('.mobile-agency-menu-bar, .mobile-menu-wrapper-agency').remove();
    }

    // Mobile menu toggles
    var $mobileToggles = $('.mobile-main-menu-toggle, .mobile-agency-menu-toggle, .inline-mobile-agency-menu-toggle');
    $mobileToggles.each(function() {
      var target = $(this).attr('data-toggle-target');
      var $target = $('.mobile-menu-wrapper-' + target);
      $(this).on('click', function(e) {
        e.preventDefault();
        var toggleDirection = ($target.hasClass('show-mobile-menu')) ? 'close' : 'open';
        toggleMenus(target, toggleDirection, $(this));
      })
    });

    // More link click events
    $('.more-dropdown-link').on('click', function(e) {
      e.preventDefault();
    });

    // Close all menus on escape key
    $(document).keyup(function(e) {
      if (e.which == 27) {
        toggleMenus('all', 'close');
      }
    });

    // Add aria-haspopup to links that have dropdowns (NOT the mobile sized menus)
    $('.scroll-menu-wrapper .menu__item, .header-wrapper .menu__item').each(function() {
      var $this = $(this);
      if ($this.children('ul').length > 0) {
        $this.children('.menu__link').attr('aria-haspopup', 'true');
      }
    });

    /**
     * Handle general menu events and responses to control focus
     */
    var menuTimeout;
    $(document).on('menus.opening.all menus.opening.main', function() {
      menuTimeout = setTimeout(function() {
        var $toggleItem = $('.mobile-main-menu-bar .mobile-main-menu-toggle');
        $toggleItem.focus();
      }, 700);
    });
    $(document).on('menus.opening.agency', function() {
      menuTimeout = setTimeout(function() {
        var $toggleItem = $('.mobile-agency-menu-bar .mobile-agency-menu-toggle');
        $toggleItem.focus();
      }, 700);
    });
    $(document).on('menus.closing.all menus.closing.agency menus.closing.main', function() {
      clearTimeout(menuTimeout);
    });


    /**
     * Close menus when moving to large screens
     */
    $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
      if (newSize === 'xlarge' || newSize === 'xxlarge') {
        toggleMenus('all', 'close');
      }
    });


    /**
     * Init accordions
     */
    var $fAccordions = $('.accordion');
    if ($fAccordions.length > 0) {
      var options = {
        allowAllClosed: true,
        slideSpeed: 250
      };
      $fAccordions.each(function() {
        var $accordionItems = $(this).children('.accordion-item');
        if (!$accordionItems.children('li.is-active').length > 0) {
         // $accordionItems.first().addClass('is-active');
        }
        var accordion = new Foundation.Accordion($(this), options);
      });
    }

    /**
     * Hide these things until foundation has initialized and finished all of its related work
     */
    $body.removeClass('f-not-processed');

    /**
     * Reset keybindings for DropdownMenu to return enter to default behavior.
     * This must be done after the components are initialized it seems.
     */
    Foundation.Keyboard.register('DropdownMenu', {
      //'ENTER': 'open',
      'SPACE': 'open',
      'ARROW_RIGHT': 'next',
      'ARROW_UP': 'up',
      'ARROW_DOWN': 'down',
      'ARROW_LEFT': 'previous',
      'ESCAPE': 'close'
    });

  });


  /**
   * Initialize foundation tabs here, in this Drupal behavior, as an offering up to El Drupalo.
   */
  Drupal.behaviors.foundationTabs = {
    attach: function (context) {
      // Inits tabs
      var $fTabs = $('.tabs-holder');
      if ($fTabs.length > 0) {
        $fTabs.each(function() {
          $(this).once('zf-tabs', function() {
            var $tabs = $(this).children('.f-tabs');
            var $tabsContent = $(this).children('.tabs-content');

            // Check to make sure there exists an active tab and tab content, if not, assign the first of each before init
            if (!$tabs.children('li.is-active').length > 0) {
              $tabs.children('li.tabs-title').first().addClass('is-active');
              $tabsContent.children('.tabs-panel').first().addClass('is-active');
            }

            var tabs = new Foundation.Tabs($(this));
            $(this).zfTabsToSelect();
          });
        });
      }
    }
  };

}(jQuery);

